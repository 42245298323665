import axios from "axios";

export const host = process.env.REACT_APP_BACKEND_URL;
const local = "http://localhost:4000";

export const MasterLogin = async (info) => {
  try {
    const { data } = await axios.post(`${host}/api/masterLogin`, info);
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const socketLogin = async (info) => {
  try {
    const { data } = await axios.post(`${host}/socketLogin`, info);
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const AdminRegister = async (info, token) => {
  try {
    const { data } = await axios.post(`${host}/adminRegister`, info, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const fetchSingleSubAdmin = async (id, token) => {
  try {
    const { data } = await axios.get(`${host}/api/fetchSingleSubAdmin/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getAllSubAdmins = async (searchData, token) => {
  try {
    const { data } = await axios.get(
      `${host}/api/getAllSubAdmins?search=${searchData}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const editSubAdmin = async (token, datas, id) => {
  try {
    const { data } = await axios.put(`${host}/api/editSubAdmin/${id}`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateSubscription = async (adminId, docId, datas, token) => {
  try {
    const { data } = await axios.put(
      `${host}/api/upgradePackege?admin=${adminId}&docId=${docId}`,
      datas,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const fetchSinglePackage = async (id, token) => {
  try {
    const { data } = await axios.get(`${host}/api/fetchSinglePackage/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const AllPackages = async (token) => {
  try {
    const { data } = await axios.get(`${host}/api/getAllPackages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getBillingHistory = async (id, token) => {
  try {
    const { data } = await axios.get(`${host}/api/getBillingHistory/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const viewMasterActivePakages = async (id, token) => {
  try {
    const { data } = await axios.get(`${host}/api/viewPackage/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const packageReport = async (token, name) => {
  try {
    const { data } = await axios.get(
      `${host}/api/packageReport?package=${name}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const packageChartValue = async (token) => {
  try {
    const { data } = await axios.get(`${host}/api/packageChartValue`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const editPackages = async (formData, id, token) => {
  try {
    const { data } = await axios.put(
      `${host}/api/editPackages/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const addRatio = async (info, token) => {
  try {
    const { data } = await axios.post(`${host}/api/addRatio`, info, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getRatio = async () => {
  try {
    const { data } = await axios.get(`${host}/getRatio`);
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const deleteRatio = async (id, token) => {
  try {
    const { data } = await axios.delete(`${host}/api/deleteRatio/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getSockets = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getSockets`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};
export const getNewSocketUrls = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getNewSockets`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const selectSocket = async (id, token) => {
  try {
    const { data } = await axios.put(
      `${host}/selectSocket/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const selectNewSocket = async (id, token) => {
  try {
    const { data } = await axios.put(
      `${host}/selectNewSocket/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const blockAndUnblockMobileApp = async (token, id, status) => {
  try {
    const { data } = await axios.patch(
      `${host}/api/v1/master/app-block/${id}`,
      { status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const activeAndInactiveVendor = async (token, id, status) => {
  try {
    const { data } = await axios.put(
      `${host}/activeAndInactiveVendor/${id}`,
      { status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateVolatileAmount = async (token, values) => {
  try {
    const { data } = await axios.put(`${host}/updateVolatileAmount`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getVolatileAmount = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getVolatileAmount`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
