import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Logout } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import PercentIcon from "@mui/icons-material/Percent";
import PersonIcon from "@mui/icons-material/Person";
import LinkIcon from "@mui/icons-material/Link";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

const drawerWidth = 240;

function MasterSideBar(props) {
  const location = useLocation();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    Cookies.set("master", "");
    dispatch({
      type: "MASTER_LOGOUT",
    });
    navigate("/masterlogin");
  };
  const details = [
    { name: "Customers", href: "/alladmins" },
    { name: "Packages", href: "/packages" },
    { name: "Socket", href: "/socket" },
    { name: "Ratio", href: "/ratio" },
    { name: "Economic News", href: "/economicnews" },
    { name: "Volatile Amount", href: "/volatile" },
  ];
  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <Divider />
      <List>
        {details.map((item, index) => (
          <Box
            sx={{ bgcolor: location.pathname == item.href ? "#F3F6FB" : "" }}
          >
            <Link
              to={item.href}
              style={{ textDecoration: "none", color: "black" }}
            >
              <ListItem key={index} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index === 0 ? (
                      <PersonIcon />
                    ) : index === 1 ? (
                      <CardMembershipIcon />
                    ) : index === 2 ? (
                      <LinkIcon />
                    ) : index === 3 ? (
                      <PercentIcon />
                    ) : (
                      <NotificationsNoneIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            </Link>
          </Box>
        ))}
        <ListItem onClick={handleLogout} sx={{ paddingX: "0px" }}>
          <ListItemButton>
            <ListItemIcon>
              <Logout></Logout>
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          backgroundColor: "#EAEAEA",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            MoneyScope Master
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

MasterSideBar.propTypes = {
  window: PropTypes.func,
};

export default MasterSideBar;
