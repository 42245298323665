import { Box, Button } from "@mui/material";
import MasterSideBar from "../../components/MasterAdmin/MasterSideBar";
import { getVolatileAmount, updateVolatileAmount } from "../../service/master";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import { voltaileSchema } from "../../schemas";
import { toast } from "react-hot-toast";

function Volatile() {
  const master = useSelector((state) => state.master);
  const [volAmount, setVolAmount] = useState();

  const initialValues = { amount: "" };
  const handleSubmit = async (values, action) => {
    const data = await updateVolatileAmount(master.token, values);
    toast.success(data.message);
    action.resetForm();
    getVol();
  };
  const getVol = async () => {
    const data = await getVolatileAmount(master.token);
    setVolAmount(data.amount);
  };
  useEffect(() => {
    getVol();
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
        }}
      >
        <div>
          <MasterSideBar />
        </div>
        <Box
          sx={{
            width: "100%",
            marginTop: "60px",
            paddingX: "20px",
            paddingTop: "20px",
          }}
        >
          <h4>Current Volatile Amount: {volAmount}</h4>
          <Formik
            initialValues={initialValues}
            validationSchema={voltaileSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors }) => (
              <Form autoComplete="off">
                <Field component={TextField} size="small" name="amount" />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    ml: 1,
                    width: "100px",
                    textTransform: "none",
                    background: "#000000",
                    "&:hover": {
                      backgroundColor: "#000000",
                    },
                  }}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
}

export default Volatile;
