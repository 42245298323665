import * as Yup from "yup";

export const addCommoditySchema = Yup.object({
  commodity_title: Yup.string().required("required"),
  purity: Yup.string().required("required"),
  premium: Yup.string().required(" required"),
  charges: Yup.string().required("required"),
  unitLabel: Yup.string().required("required"),
  unit: Yup.string().required("required"),
  buy_premium: Yup.number().required("required"),
  buy_charge: Yup.number().required("required"),
});

export const editSpreadSchema = Yup.object({
  spread: Yup.string().required("Spread is required"),
});

export const editLowHighValueSpreadSchema = Yup.object({
  low_value: Yup.number().required("Low value is required"),
  high_value: Yup.number().required("High value is required"),
});

export const addBankDetailsSchema = Yup.object({
  holderName: Yup.string().required("Holder Name is required"),
  bank: Yup.string().min(2).max(25).required("Please Enter Bank Name"),
  accountNumber: Yup.string()
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      "Please Enter numbers only"
    )
    .required("Please Entenr Account Number"),
  IBAN: Yup.string().required("Please Enter Your IBAN"),
  IFSC: Yup.string().required("Please Enter Your IFSC"),
  branch: Yup.string().required("Please Enter Your Branch"),
  city: Yup.string().required("Please Enter Your city"),
  country: Yup.string().required("Please Enter Your country"),
  logo: Yup.string().required("Please Choose Your logo"),
});

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
export const addAdminSchema = Yup.object().shape({
  firstName: Yup.string().required("required"),
  lastName: Yup.string().required("required"),
  email: Yup.string().email("invalid email").required("required"),
  contact: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  address1: Yup.string().required("required"),
  address2: Yup.string().required("required"),
});

export const addTraderSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  mobile: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .required("Mobile number is required"),
  whatsApp: Yup.string()
    .matches(phoneRegExp, "WhatsApp number is not valid")
    .required("WhatsApp number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  nationality: Yup.string().required("Nationality is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  addressLine1: Yup.string().required("Address Line1 is required"),
  addressLine2: Yup.string(),
  gender: Yup.string(),
  postalCode: Yup.string(),
  languages: Yup.string(),
  idType: Yup.string(),
  idNumber: Yup.string(),
  password: Yup.string().required("Password is required"),
});

export const editTraderSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  mobile: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .required("Mobile number is required"),
  whatsApp: Yup.string()
    .matches(phoneRegExp, "WhatsApp number is not valid")
    .required("WhatsApp number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  nationality: Yup.string().required("Nationality is required"),
  country: Yup.string().required("Country is required"),
  city: Yup.string().required("City is required"),
  addressLine1: Yup.string().required("Address Line1 is required"),
  addressLine2: Yup.string(),
  gender: Yup.string(),
  postalCode: Yup.string(),
  languages: Yup.string(),
  idType: Yup.string(),
  idNumber: Yup.string(),
  proofofIdFrontside: Yup.string(),
  proofOfIdBackside: Yup.string(),
});

export const placeOrderSchema = Yup.object({
  trading_account: Yup.string().required("Trading Account No is required"),
  commodity: Yup.string().required("Metal is required"),
  typeOfOrder: Yup.string().required("Yype Of Order is required"),
  price: Yup.string().required("Price is required"),
  lotSize: Yup.string().required("LotSize is required"),
  stopLoss: Yup.string(),
  target: Yup.string(),
});

export const messageSchema = Yup.object({
  message: Yup.string().required("required"),
});

export const newsSchema = Yup.object({
  newsTitle: Yup.string().required("required"),
  newsDetails: Yup.string().required("required"),
});

export const packageSchema = Yup.object({
  packageName: Yup.string().required("required"),
  price: Yup.string().required("required"),
});

export const loginSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});
export const londonFixSchema = Yup.object({
  gold: Yup.object({
    date: Yup.date().required("Gold date is required"),
    amValue: Yup.string().required("Gold AM Value is required"),
    pmValue: Yup.string().required("Gold PM Value is required"),
  }),
  silver: Yup.object({
    date: Yup.date().required("Silver date is required"),
    amValue: Yup.string().required("Silver AM Value is required"),
    pmValue: Yup.string().required("Silver PM Value is required"),
  }),
});

export const voltaileSchema = Yup.object({
  amount: Yup.number()
    .typeError("field must be a number")
    .required("amount is required"),
});

export const selectCurrencySchema = Yup.object().shape({
  baseCurrency: Yup.object()
    .typeError("Base Currency is required")
    .required("Base Currency is required"),
  quoteCurrency: Yup.array().min(
    1,
    "You need to provide at least 1 Quote Currencyl"
  ),
});

export const addManagerSchema = Yup.object({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  mobile: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .required("Mobile number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  country: Yup.string().required("Country is required"),
  password: Yup.string().required("Password is required"),
});

export const editManagerSchema = Yup.object({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  mobile: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .required("Mobile number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  country: Yup.string().required("Country is required"),
});

export const economicnewsSchema = Yup.object({
  date: Yup.string().required("Date is required"),
  news: Yup.string().required("News is required"),
  priority: Yup.string().required("Priority is required"),
});

export const adminAddressSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  street: Yup.string().required("Street is required"),
  city: Yup.string().required("City is required"),
  map_url: Yup.string().required("Google Map Location is required"),
  email: Yup.string().email().required("Email is required"),
  mobile: Yup.string().required("Mobile is required"),
  whatsapp: Yup.string().required("Whatsapp is required"),
});
