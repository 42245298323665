import { useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";
function UserSideSetting() {
  const isUser = useSelector((state) => state.user);
  const location = useLocation();
  return (
    <div style={{ display: "flex" }}>
      {(isUser && location.pathname === "/") ||
      location.pathname === "/dashboard/calendar" ||
      location.pathname === "/bookingdesk" ||
      location.pathname === "/spotrate" ||
      location.pathname === "/customers" ||
      location.pathname === "/spotorders" ||
      location.pathname === "/managers" ||
      location.pathname === "/transactions" ||
      location.pathname === "/settings" ? (
        <Sidebar />
      ) : (
        ""
      )}
    </div>
  );
}

export default UserSideSetting;
