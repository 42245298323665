import "./App.css";
import { Route, Routes } from "react-router-dom";
import React, { lazy, Suspense, useEffect, useState } from "react";
import UserSideSetting from "./components/admins/UserSideSetting";
import AdminFooter from "./components/admins/AdminFooter";
import { getCurrentlyPackage } from "./service/api";
import { useSelector } from "react-redux";
import Volatile from "./pages/masterAdmin/Volatile";
import CheckTockenExp from "./helpers/CheckTockenExp";
import { useMediaQuery } from "@mui/material";
import io from "socket.io-client";
import MobileViewErrorMessage from "./pages/admins/MobileViewErrorMessage";
import { Toaster } from "react-hot-toast";

const LoggedInAdmin = lazy(() => import("./Routes/admin/LoggedInAdmin"));
const LoggedInMaster = lazy(() => import("./Routes/master/LoggedInMaster"));
const MasterLoginPage = lazy(() =>
  import("./pages/masterAdmin/MasterLoginPage")
);
const AllAdminsList = lazy(() => import("./pages/masterAdmin/AllAdminsList"));
const Dashboard = lazy(() => import("./pages/admins/Dashboard"));
const BookingDesk = lazy(() => import("./pages/admins/BookingDesk"));
const CustomersList = lazy(() => import("./pages/admins/CustomersList"));
const Calendar = lazy(() => import("./pages/admins/Calendar"));
const Tranasactions = lazy(() => import("./pages/admins/Tranasactions"));
const NotLoggedInRoutes = lazy(() => import("./Routes/NotLoggedInRoutes"));
const UserNotLoggedIn = lazy(() => import("./Routes/UserNotLoggedIn"));
const Settings = lazy(() => import("./pages/admins/Settings"));
const Packages = lazy(() => import("./pages/masterAdmin/Packages"));
const Ratio = lazy(() => import("./pages/masterAdmin/Ratio"));
const EconomicNews = lazy(() => import("./pages/masterAdmin/EconomicNews"));
const SocketURL = lazy(() => import("./pages/masterAdmin/SocketURL"));
const SpotRate = lazy(() => import("./pages/admins/SpotRate"));
const SpotOrder = lazy(() => import("./pages/admins/SpotOrders"));
const Managers = lazy(() => import("./pages/admins/Managers"));
const AdminLogin = lazy(() => import("./pages/admins/AdminLogin"));
const ErrorPage = lazy(() => import("./pages/admins/ErrorPage"));

var socket = io(process.env.REACT_APP_BACKEND_URL);

function App() {
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");
  const user = useSelector((state) => state?.user);
  const Lite = [
    <Route path="/" exact element={<Dashboard />} />,
    <Route path="/dashboard/calendar" exact element={<Calendar />} />,
    <Route path="/spotrate" exact element={<SpotRate />} />,
    <Route path="/settings" exact element={<Settings />} />,
  ];
  const Pro = [
    <Route path="/" exact element={<Dashboard />} />,
    <Route path="/dashboard/calendar" exact element={<Calendar />} />,
    <Route path="/spotrate" exact element={<SpotRate />} />,
    <Route path="/bookingdesk" exact element={<BookingDesk />} />,
    <Route path="/customers" exact element={<CustomersList />} />,
    <Route path="/transactions" exact element={<Tranasactions />} />,
    // <Route path="/managers" exact element={<Managers />} />,
    user?.spotOrders && (
      <Route path="/spotorders" exact element={<SpotOrder />} />
    ),
    <Route path="/settings" exact element={<Settings />} />,
  ];
  const [render, setRerender] = useState("");
  useEffect(() => {
    socket.on("connect", () => {});
    socket.on("disconnect", () => {});
    socket.emit("join_room", { roomId: user?.id });
    socket.on("upgradeRerender", (data) => {
      setRerender(data?.value);
    });
  }, []);

  const [state, setState] = useState(
    localStorage.getItem("package") === "Pro" ? Pro : Lite
  );
  const handleCheck = async () => {
    if (user) {
      const data = await getCurrentlyPackage(user?.token);
      if (data.package === "Standard" || data.package === "Pro") {
        localStorage.setItem("package", "Pro");
        setState(Pro);
      } else {
        localStorage.setItem("package", "Lite");
        setState(Lite);
      }
    }
  };
  useEffect(() => {
    handleCheck();
  }, [render]);

  return (
    <>
      <Suspense fallback={""}>
        {isLargeScreen ? (
          <div style={{ display: "flex" }}>
            <Toaster />
            {user && (
              <CheckTockenExp
                token={
                  user?.token
                    ? user?.token
                    : "eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJVc2VybmFtZSI6ImFqbWFsLmFydGlmaXRpYSIsImV4cCI6MTY5MjY5ODc0MywiaWF0IjoxNjkyNjk4NzQzfQ.FCG30ZERywmRjoMteTYsBM_qvt0TecDUGJWhteKRvhc"
                }
              />
            )}
            <div>
              <UserSideSetting />
            </div>
            <div style={{ width: "100%", height: "100%" }}>
              <Routes>
                <Route element={<UserNotLoggedIn />}>
                  <Route path="/login" element={<AdminLogin />} />
                </Route>
                <Route element={<LoggedInAdmin />}>
                  {state.map((item) => (
                    <>{item}</>
                  ))}
                </Route>

                <Route element={<LoggedInMaster />}>
                  <Route path="/alladmins" exact element={<AllAdminsList />} />
                  <Route path="/packages" exact element={<Packages />} />
                  <Route path="/socket" exact element={<SocketURL />} />
                  <Route path="/ratio" exact element={<Ratio />} />
                  <Route
                    path="/economicnews"
                    exact
                    element={<EconomicNews />}
                  />
                  <Route path="/volatile" exact element={<Volatile />} />
                </Route>
                <Route element={<NotLoggedInRoutes />}>
                  <Route path="/masterlogin" element={<MasterLoginPage />} />
                </Route>
                <Route path="*" element={<ErrorPage />} />
              </Routes>
              <AdminFooter />
            </div>
          </div>
        ) : (
          <MobileViewErrorMessage />
        )}
      </Suspense>
    </>
  );
}
export default App;
