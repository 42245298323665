import { useEffect } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";

var socket = io(process.env.REACT_APP_BACKEND_URL);

function SocketServer() {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    socket.on("connect", () => {});
    socket.on("disconnect", () => {});

    socket.emit("join_room", { roomId: user?.id });

    // Handle connection errors (if any)
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    socket.on("disconnect", () => {});
  }, []);

  return <></>;
}

export default SocketServer;
