import Cookies from "js-cookie";
import date from "date-and-time";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import ListItemIcon from "@mui/material/ListItemIcon";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

export default function Topbar() {
  const user = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    Cookies.set("user", "");
    dispatch({
      type: "LOGOUT",
    });
    localStorage.removeItem("loginTime");
    navigate("/login");
  };

  // Time and date functions
  const format = "DD MMM YYYY - ddd hh:mm A";
  const now = () => date.format(new Date(), format);

  const [currentTime, setCurrentTime] = useState(now);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(now);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  //////// auto logout function
  const checkLogoutTime = () => {
    const loginTime = localStorage.getItem("loginTime");
    const currentTime = Date.now();
    const elapsedTime = currentTime - parseInt(loginTime, 10);
    const logoutThreshold = 2 * 24 * 60 * 60 * 1000; // Three days in milliseconds
    if (elapsedTime > logoutThreshold) {
      logout();
    }
  };
  useEffect(() => {
    const logoutInterval = setInterval(() => {
      checkLogoutTime();
    }, 60 * 1000); // Check every minute

    return () => {
      clearInterval(logoutInterval);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "end",
        }}
      >
        <h3
          className="dateAndTime"
          style={{ marginRight: "20px", fontFamily: "poppins" }}
        >
          {currentTime}
        </h3>
        <DarkModeOutlinedIcon sx={{ marginRight: "18px" }} />
        <NotificationsNoneIcon />
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 33, height: 33, fontFamily: "poppins" }}>
              {user?.company_name.slice(0, 1)}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem sx={{ fontFamily: "poppins" }}>
          <Avatar /> {user ? user.company_name : "Profile"}
        </MenuItem>

        <MenuItem onClick={logout} sx={{ fontFamily: "poppins" }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
