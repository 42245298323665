import { combineReducers } from "redux";
import { ReminderDataReducer } from "../reducers/ReminderDataReducer";
import { userReducer } from "../reducers/CustomerReducer";
import { masterReducer } from "../reducers/MasterReducer";

const rootReducer = combineReducers({
  user: userReducer,
  master: masterReducer,
  reminder: ReminderDataReducer,
});

export default rootReducer;
