import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation } from "react-router-dom";
import Topbar from "./Topbar";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import SpotOrdersIcon from "@mui/icons-material/Dashboard";
import DashboardIcon from "@mui/icons-material/ThreeSixty";
import TimelineIcon from "@mui/icons-material/Timeline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import Groups3Icon from "@mui/icons-material/Groups3";
import { LightenDarkenColor } from "lighten-darken-color";
import { getCurrentlyPackage } from "../../service/api";
import SocketServer from "../../socket/SocketServer";
import io from "socket.io-client";

const drawerWidth = 240;
var socket = io(process.env.REACT_APP_BACKEND_URL);

function Sidebar(props) {
  const [render, setRerender] = useState("");
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useEffect(() => {
    handleCheck();
  }, [render]);

  const location = useLocation();
  const Lite = [
    {
      name: "Dashboard",
      href: "/",
      icon: <DashboardIcon style={{ color: "#FFFFFF" }} />,
    },
    {
      name: "Spot Rate",
      href: "/spotrate",
      icon: <TimelineIcon style={{ color: "#FFFFFF" }} />,
    },
    {
      name: "Settings",
      href: "/settings",
      icon: <SettingsIcon style={{ color: "#FFFFFF" }} />,
    },
  ];
  const Pro = [
    {
      name: "Dashboard",
      href: "/",
      icon: <DashboardIcon style={{ color: "#FFFFFF" }} />,
    },
    {
      name: "Booking Desk",
      href: "/bookingdesk",
      icon: <SpotOrdersIcon style={{ color: "#FFFFFF" }} />,
    },
    {
      name: "Spot Rate",
      href: "/spotrate",
      icon: <TimelineIcon style={{ color: "#FFFFFF" }} />,
    },
    {
      name: "Customers",
      href: "/customers",
      icon: <GroupIcon style={{ color: "#FFFFFF" }} />,
    },
    {
      name: "Transactions",
      href: "/transactions",
      icon: <AssignmentIcon style={{ color: "#FFFFFF" }} />,
    },
    // {
    //   name: "Managers",
    //   href: "/managers",
    //   icon: <Groups3Icon style={{ color: "#FFFFFF" }} />,
    // },
    user?.spotOrders && {
      name: "Spot Orders",
      href: "/spotorders",
      icon: <TrendingUpIcon style={{ color: "#FFFFFF" }} />,
    },
    {
      name: "Settings",
      href: "/settings",
      icon: <SettingsIcon style={{ color: "#FFFFFF" }} />,
    },
  ];

  useEffect(() => {
    socket.on("connect", () => {});
    socket.on("disconnect", () => {});
    socket.emit("join_room", { roomId: user?.id });
    socket.on("upgradeRerender", (data) => {
      setRerender(data?.value);
    });
  }, []);
  const [details, setDetails] = useState(
    localStorage.getItem("package") === "Pro" ? Pro : Lite
  );
  const handleCheck = async () => {
    const data = await getCurrentlyPackage(user?.token);
    if (data?.package === "Standard" || data?.package === "Pro") {
      localStorage.setItem("package", "Pro");
      setDetails(Pro);
    } else {
      localStorage.setItem("package", "Lite");
      setDetails(Lite);
    }
  };

  const drawer = (
    <Box
      sx={{
        backgroundColor: user?.colorCode,
        height: "100%",
        overflow: "auto",
      }}
    >
      <SocketServer />
      <Stack
        borderRadius="50%"
        direction="row"
        justifyContent={"center"}
        marginBottom={"2rem"}
        marginTop={"5rem"}
      >
        <img
          src={user?.logo}
          alt="logo"
          width={"125px"}
          height={"125px"}
          style={{ borderRadius: "50%" }}
        />
      </Stack>
      <List>
        {user &&
          details
            .filter((item) => item !== false)
            .map((item, index) => (
              <Link
                to={item.href}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    disablePadding
                    sx={{
                      background:
                        location?.pathname == item?.href
                          ? LightenDarkenColor(user?.colorCode, 50)
                          : "",
                      ":hover": {
                        bgcolor:
                          location?.pathname == item?.href
                            ? LightenDarkenColor(user?.colorCode, 50)
                            : LightenDarkenColor(user?.colorCode, 20),
                      },
                    }}
                  >
                    <ListItemIcon style={{ marginLeft: "20px" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontFamily: "poppins" }}
                      primary={item.name}
                      style={{ color: "#FFFFFF" }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            backgroundColor: "#E9E9EE",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <IconButton
              color="#F7A928"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Topbar />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

Sidebar.propTypes = {
  window: PropTypes.func,
};

export default Sidebar;
