import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(2, 2),
    marginTop: "auto",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
}));
function AdminFooter() {
  const classes = useStyles();
  const location = useLocation();
  return (
    <>
      {location.pathname !== "/masterlogin" &&
        location.pathname !== "/alladmins" &&
        location.pathname !== "/packages" &&
        location.pathname !== "/socket" &&
        location.pathname !== "/login" &&
        location.pathname !== "/volatile" &&
        location.pathname !== "/settings" &&
        location.pathname !== "/ratio" && (
          <footer className={classes.footer}>
            <Typography style={{ font: "3px", fontFamily: "poppins" }}>
              2022 © Bullionstats
            </Typography>
            <Typography style={{ font: "3px", fontFamily: "poppins" }}>
              Design & Developed by Bullionstats
            </Typography>
          </footer>
        )}
    </>
  );
}

export default AdminFooter;
