import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

function CheckTockenExp({ token }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    var decoded = jwt_decode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    if (decoded?.exp < currentTime) {
      Cookies.set("user", "");
      dispatch({
        type: "LOGOUT",
      });
      localStorage.removeItem("loginTime");
      navigate("/login");
    }
  }, [navigate]);

  return <div>{/* Protected content */}</div>;
}

export default CheckTockenExp;
